@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
.height-override .sendbird-channel-list__header{
    height: unset;
}
button.sendbird-message-input--attach {
    display: none;
}
.sendbird-badge__text {
    display: flex;
    height: inherit;
    justify-content: center;
}
.sendbird-badge__text > span{
    margin-top: auto;
    margin-bottom: auto;
}
.sendbird-channel-preview:hover .sendbird-channel-preview__action button.sendbird-iconbutton {
    display: none;
}
.sendbird-conversation__messages__notification {
  margin-top: 0 !important;
}

.circle-shape-cropper-container .cropper-crop-box,
.circle-shape-cropper-container .cropper-view-box {
  border-radius: 50%;
  outline: 1px solid #F8A03A;
  outline-style: dashed;
}
.circle-shape-cropper-container .cropper-face {
  background: transparent;
}

.circle-shape-cropper-container .cropper-line,
.circle-shape-cropper-container .cropper-point
{
  background-color: #F8A03A;
}

.rdrCalendarWrapper .rdrSelected {
  color: #F8A03A !important;
}

.rdrCalendarWrapper .rdrDayStartPreview.rdrDayEndPreview {
  border: none;
}

.rdrCalendarWrapper .rdrDayToday .rdrDayNumber span:after {
  background-color: #F8A03A !important;
}

.rdrCalendarWrapper .rdrDay.rdrDayHovered .rdrDayNumber:after {
  border-color: #F8A03A !important;
}

.rdrCalendarWrapper .rdrDateDisplayWrapper {
  display: none;
}

.apexcharts-legend {
  flex-direction: column;
}

.custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

@media (min-width: 578px) {
    /** Mozilla scrollbar*/
    * {
      scrollbar-color: #CCC !important;
      scrollbar-width: thin !important;
    }

    /** Scrollbar for browser based on webkit */
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: #CCCCCC;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #AFAFAF;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #6b6b6b;
    }
    ::-webkit-scrollbar-track {
      background: rgba(204, 204, 204, 0.3);
    }
  }